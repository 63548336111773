
import { UseModal, useModal } from '@/composable/useModal'
import { format } from 'date-fns'
import { computed, defineComponent, inject, provide } from 'vue'
import orderBy from 'lodash.orderby'
import { SearchDelay } from '@/types/search-delay'
import { getMpkEvent } from '@/api/mpk/getMpkEvent'
import { searchDelay } from '@/api/search/searchDelay'
import { searchPrevTripFromTraind } from '@/api/search/prevTripFromTraind'
import { SearchPrevTripFromTraind } from '@/types/SearchPrevTripFromTraind'
import { useRoute } from 'vue-router'
import { useProfile } from '@/composable/useProfile'

export default defineComponent({
  name: 'DeviationDelayModal',

  setup() {
    const { can } = useProfile()
    const route = useRoute()
    const project = route.params.project
    const delayModal2 = useModal()
    provide('delay-modal-2', delayModal2)
    const delayModal = inject<
      UseModal<{
        data: SearchDelay[]
        prevTripFromTraind: SearchPrevTripFromTraind[]
      }>
    >('delay-modal-2')
    const mpkModal =
      inject<
        UseModal<{ eventId: string; mpkData: { [key: string]: unknown } }>
      >('delay-mpk-modal')

    const prevTripColumns = [
      {
        align: 'left',
        name: 'train_id',
        required: true,
        label: 'Föregående tåguppdrag',
        field: (x: SearchPrevTripFromTraind) => x.prevTrip.train_id,
        sortable: false,
      },
      {
        align: 'left',
        name: 'position',
        required: true,
        label: 'Position',
        field: 'position',
        sortable: false,
      },
      {
        align: 'left',
        name: 'name',
        required: true,
        label: 'Fordon',
        field: 'name',
        sortable: false,
      },
      {
        align: 'left',
        name: 'layover',
        required: true,
        label: 'Uppehållstid (min)',
        field: (x: SearchPrevTripFromTraind) => x.prevTrip.layover,
        sortable: false,
      },
      {
        align: 'left',
        name: 'delay',
        required: true,
        label: 'Försening (min)',
        field: (x: SearchPrevTripFromTraind) => x.prevTrip.delay,
        sortable: false,
      },
    ]

    const columns = [
      {
        align: 'left',
        name: 'core_parsed',
        required: true,
        label: 'Core',
        field: 'core_parsed',
        sortable: false,
      },
      {
        align: 'left',
        name: 'stop_short',
        required: true,
        label: 'Trafikplats signatur',
        field: 'stop_short',
        sortable: false,
      },
      {
        align: 'left',
        name: 'stop_long',
        required: true,
        label: 'Trafikplats',
        field: 'stop_long',
        sortable: false,
      },
      {
        align: 'left',
        name: 'ank_planned',
        required: true,
        label: 'Ank.',
        field: 'ank_planned',
        format: (v: string) => (v ? format(new Date(v), 'HH:mm') : ''),
        sortable: false,
      },
      {
        align: 'left',
        name: 'avg_planned',
        required: true,
        label: 'Avg.',
        field: 'avg_planned',
        format: (v: string) => (v ? format(new Date(v), 'HH:mm') : ''),
        sortable: false,
      },
      {
        align: 'left',
        name: 'delay_minutes',
        required: true,
        label: 'Försening (min)',
        field: 'delay_minutes',
        sortable: false,
      },
      {
        align: 'left',
        name: 'delay_reason',
        required: true,
        label: 'Kod',
        field: 'delay_reason',
        sortable: false,
      },
      {
        align: 'left',
        name: 'level_3_description',
        required: true,
        label: 'Orsak',
        field: 'level_3_description',
        sortable: false,
      },
      {
        align: 'left',
        name: 'delay_reason_description',
        required: true,
        label: '',
        field: 'delay_reason_description',
        sortable: false,
      },
    ]

    type DataItem = SearchDelay & { avg_planned: string; ank_planned: string }

    const getColor = (v: number) => {
      if (v > 5) return 'red'
      if (v < 0) return 'lightblue'
      if (v === 0) return 'green'
      return 'orange'
    }

    const mapItem = (x: SearchDelay): DataItem => ({
      ...x,
      avg_planned: ['DEPARTURE', 'RUN_THROUGH'].includes(x.stop_type_long)
        ? x.planned
        : '',
      ank_planned: x.stop_type_long === 'ARRIVAL' ? x.planned : '',
    })

    const sortedData = (delayModal?.state.data?.data || []).map(mapItem)

    const prevTripsFromTraind = computed(() => {
      return delayModal?.state.data?.prevTripFromTraind || []
    })

    function onClickMpkChip(mpk_event_id: string) {
      const eventId = mpk_event_id.split(' ')[0].replace('H', '')
      mpkModal?.openModal({
        cb: async (setData) => {
          await getMpkEvent(eventId)
            .then(({ data }) => {
              setData({
                eventId,
                mpkData: data,
              })
            })
            .catch((error) => {
              setData(error.response.data.message)
            })

          return Promise.resolve()
        },
      })
    }

    function onClickOpenDelay({
      technical,
      departure,
    }: {
      technical: string
      departure: string
    }) {
      delayModal2?.openModal({
        cb: async (setData) => {
          const params = {
            technical: Number(technical),
            departureDate: format(new Date(departure), 'yyyy-MM-dd'),
          }
          await Promise.all([
            searchDelay(params),
            searchPrevTripFromTraind({
              tnr: params.technical,
              date: params.departureDate,
            }),
          ])
            .then(([{ data }, { data: prevTripFromTraind }]) => {
              setData({
                data,
                prevTripFromTraind,
              })
            })
            .catch((error) => {
              setData(error.response.data.message)
            })

          return Promise.resolve()
        },
      })
    }

    const gotTo = (url: string) => {
      return `/${route.params.project}/graph/${url}`
    }

    return {
      delayModal,
      delayModal2,
      sortedData,
      columns,
      prevTripColumns,
      orderBy,
      getColor,
      onClickMpkChip,
      onClickOpenDelay,
      gotTo,
      can,
      project,
      prevTripsFromTraind,
    }
  },
})
