import { instance } from '@/services/avik-api-gateway'
import { SearchDelay } from '@/types/search-delay'
import { AxiosResponse } from 'axios'

export interface SearchDelayParams {
  technical: string | number
  departureDate: string
}

export function searchDelay(
  params: SearchDelayParams
): Promise<AxiosResponse<SearchDelay[]>> {
  return instance.get(`/search/delay`, {
    params,
  })
}
