import { instance } from '@/services/avik-api-gateway'
import { SearchPrevTripFromTraind } from '@/types/SearchPrevTripFromTraind'
import { AxiosResponse } from 'axios'

export interface SearchTrainCompostionParams {
  tnr: number
  date: string
}

export function searchPrevTripFromTraind(
  params: SearchTrainCompostionParams
): Promise<AxiosResponse<SearchPrevTripFromTraind>> {
  return instance.get(`/search/prev-trip-from-traind`, {
    params,
  })
}
