import { render } from "./DeviationDelayMPKModal.vue?vue&type=template&id=89fbcc22"
import script from "./DeviationDelayMPKModal.vue?vue&type=script&lang=ts"
export * from "./DeviationDelayMPKModal.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QSpace,QToggle,QSeparator,QCardActions,QBtn});
