<template>
  <q-card style="min-width: 900px">
    <q-dialog v-model="delayModal2.state.show" :persistent="false">
      <DeviationDelayModal v-if="delayModal2.state.show" />
    </q-dialog>
    <q-card-section class="row items-center text-h6">
      Förseningar
      <q-separator vertical class="q-mx-sm" />
      Tåg
      {{ delayModal?.state.data?.prevTripFromTraind[0].thisTrip.train_id }}
      <q-separator vertical class="q-mx-sm" />
      {{ delayModal?.state.data?.prevTripFromTraind[0].thisTrip.time_start }}
      <q-btn
        v-if="delayModal?.state.data?.prevTripFromTraind[0].thisTrip?.train_id"
        icon="mdi-graph"
        size="sm"
        outline
        color="primary"
        class="q-ml-md"
        :to="
          gotTo(
            `${
              delayModal?.state.data?.prevTripFromTraind[0].thisTrip?.train_id
            }/${delayModal?.state.data?.prevTripFromTraind[0].thisTrip?.time_start.substring(
              0,
              10
            )}`
          )
        "
        >Graf</q-btn
      >
    </q-card-section>

    <q-card-section v-if="prevTripsFromTraind.length">
      <q-table
        outlined
        hide-bottom
        dense
        sort
        :rows-per-page-options="[0]"
        :rows="prevTripsFromTraind"
        :columns="prevTripColumns"
      >
        <template #body-cell-train_id="props">
          <q-td :props="props">
            <q-btn
              outline
              dense
              size="12px"
              class="q-mb-xs"
              @click.stop="
                onClickOpenDelay({
                  departure: props.row.prevTrip.departure_date,
                  technical: props.row.prevTrip.train_id,
                })
              "
              >{{ props.row.prevTrip.train_id }}</q-btn
            >
          </q-td>
        </template>
      </q-table>
    </q-card-section>

    <q-card-section class="q-pa-none" v-if="sortedData.length">
      <q-table
        flat
        hide-bottom
        dense
        sort
        :rows-per-page-options="[0]"
        :rows="sortedData"
        :columns="columns"
      >
        <template #body-cell-delay_minutes="props">
          <q-td
            :props="props"
            class="text-bold"
            :style="{ color: getColor(props.row.delay_minutes) }"
          >
            {{ props.row.delay_minutes }}
          </q-td>
        </template>

        <template #body-cell-delay_reason_description="props">
          <q-td :props="props">
            <q-btn
              v-if="
                props.row.delay_reason_description &&
                props.row.delay_reason_description[0] === 'H'
              "
              outline
              dense
              size="12px"
              class="q-mb-xs"
              @click.stop="onClickMpkChip(props.row.delay_reason_description)"
            >
              {{ props.row.delay_reason_description.split('(')[0] }}
            </q-btn>
            <template
              v-else-if="
                props.row.delay_reason_description &&
                props.row.delay_reason_description.length
              "
            >
              <q-btn
                outline
                dense
                size="12px"
                class="q-mb-xs"
                @click.stop="
                  onClickOpenDelay({
                    departure: props.row.departure_date,
                    technical: props.row.delay_reason_description,
                  })
                "
                >{{ props.row.delay_reason_description.split('(')[0] }}</q-btn
              >
            </template>
          </q-td>
        </template>
      </q-table>
    </q-card-section>
    <q-card-section v-else> Ingen data hittades... </q-card-section>
    <q-separator />
    <q-card-actions align="right">
      <q-btn flat label="Stäng" @click="delayModal.closeModal" type="button" />
    </q-card-actions>
  </q-card>
</template>

<script lang="ts">
import { UseModal, useModal } from '@/composable/useModal'
import { format } from 'date-fns'
import { computed, defineComponent, inject, provide } from 'vue'
import orderBy from 'lodash.orderby'
import { SearchDelay } from '@/types/search-delay'
import { getMpkEvent } from '@/api/mpk/getMpkEvent'
import { searchDelay } from '@/api/search/searchDelay'
import { searchPrevTripFromTraind } from '@/api/search/prevTripFromTraind'
import { SearchPrevTripFromTraind } from '@/types/SearchPrevTripFromTraind'
import { useRoute } from 'vue-router'
import { useProfile } from '@/composable/useProfile'

export default defineComponent({
  name: 'DeviationDelayModal',

  setup() {
    const { can } = useProfile()
    const route = useRoute()
    const project = route.params.project
    const delayModal2 = useModal()
    provide('delay-modal-2', delayModal2)
    const delayModal = inject<
      UseModal<{
        data: SearchDelay[]
        prevTripFromTraind: SearchPrevTripFromTraind[]
      }>
    >('delay-modal-2')
    const mpkModal =
      inject<
        UseModal<{ eventId: string; mpkData: { [key: string]: unknown } }>
      >('delay-mpk-modal')

    const prevTripColumns = [
      {
        align: 'left',
        name: 'train_id',
        required: true,
        label: 'Föregående tåguppdrag',
        field: (x: SearchPrevTripFromTraind) => x.prevTrip.train_id,
        sortable: false,
      },
      {
        align: 'left',
        name: 'position',
        required: true,
        label: 'Position',
        field: 'position',
        sortable: false,
      },
      {
        align: 'left',
        name: 'name',
        required: true,
        label: 'Fordon',
        field: 'name',
        sortable: false,
      },
      {
        align: 'left',
        name: 'layover',
        required: true,
        label: 'Uppehållstid (min)',
        field: (x: SearchPrevTripFromTraind) => x.prevTrip.layover,
        sortable: false,
      },
      {
        align: 'left',
        name: 'delay',
        required: true,
        label: 'Försening (min)',
        field: (x: SearchPrevTripFromTraind) => x.prevTrip.delay,
        sortable: false,
      },
    ]

    const columns = [
      {
        align: 'left',
        name: 'core_parsed',
        required: true,
        label: 'Core',
        field: 'core_parsed',
        sortable: false,
      },
      {
        align: 'left',
        name: 'stop_short',
        required: true,
        label: 'Trafikplats signatur',
        field: 'stop_short',
        sortable: false,
      },
      {
        align: 'left',
        name: 'stop_long',
        required: true,
        label: 'Trafikplats',
        field: 'stop_long',
        sortable: false,
      },
      {
        align: 'left',
        name: 'ank_planned',
        required: true,
        label: 'Ank.',
        field: 'ank_planned',
        format: (v: string) => (v ? format(new Date(v), 'HH:mm') : ''),
        sortable: false,
      },
      {
        align: 'left',
        name: 'avg_planned',
        required: true,
        label: 'Avg.',
        field: 'avg_planned',
        format: (v: string) => (v ? format(new Date(v), 'HH:mm') : ''),
        sortable: false,
      },
      {
        align: 'left',
        name: 'delay_minutes',
        required: true,
        label: 'Försening (min)',
        field: 'delay_minutes',
        sortable: false,
      },
      {
        align: 'left',
        name: 'delay_reason',
        required: true,
        label: 'Kod',
        field: 'delay_reason',
        sortable: false,
      },
      {
        align: 'left',
        name: 'level_3_description',
        required: true,
        label: 'Orsak',
        field: 'level_3_description',
        sortable: false,
      },
      {
        align: 'left',
        name: 'delay_reason_description',
        required: true,
        label: '',
        field: 'delay_reason_description',
        sortable: false,
      },
    ]

    type DataItem = SearchDelay & { avg_planned: string; ank_planned: string }

    const getColor = (v: number) => {
      if (v > 5) return 'red'
      if (v < 0) return 'lightblue'
      if (v === 0) return 'green'
      return 'orange'
    }

    const mapItem = (x: SearchDelay): DataItem => ({
      ...x,
      avg_planned: ['DEPARTURE', 'RUN_THROUGH'].includes(x.stop_type_long)
        ? x.planned
        : '',
      ank_planned: x.stop_type_long === 'ARRIVAL' ? x.planned : '',
    })

    const sortedData = (delayModal?.state.data?.data || []).map(mapItem)

    const prevTripsFromTraind = computed(() => {
      return delayModal?.state.data?.prevTripFromTraind || []
    })

    function onClickMpkChip(mpk_event_id: string) {
      const eventId = mpk_event_id.split(' ')[0].replace('H', '')
      mpkModal?.openModal({
        cb: async (setData) => {
          await getMpkEvent(eventId)
            .then(({ data }) => {
              setData({
                eventId,
                mpkData: data,
              })
            })
            .catch((error) => {
              setData(error.response.data.message)
            })

          return Promise.resolve()
        },
      })
    }

    function onClickOpenDelay({
      technical,
      departure,
    }: {
      technical: string
      departure: string
    }) {
      delayModal2?.openModal({
        cb: async (setData) => {
          const params = {
            technical: Number(technical),
            departureDate: format(new Date(departure), 'yyyy-MM-dd'),
          }
          await Promise.all([
            searchDelay(params),
            searchPrevTripFromTraind({
              tnr: params.technical,
              date: params.departureDate,
            }),
          ])
            .then(([{ data }, { data: prevTripFromTraind }]) => {
              setData({
                data,
                prevTripFromTraind,
              })
            })
            .catch((error) => {
              setData(error.response.data.message)
            })

          return Promise.resolve()
        },
      })
    }

    const gotTo = (url: string) => {
      return `/${route.params.project}/graph/${url}`
    }

    return {
      delayModal,
      delayModal2,
      sortedData,
      columns,
      prevTripColumns,
      orderBy,
      getColor,
      onClickMpkChip,
      onClickOpenDelay,
      gotTo,
      can,
      project,
      prevTripsFromTraind,
    }
  },
})
</script>
