<template>
  <q-card style="max-width: 900px; max-height: 800px">
    <q-card-section class="row items-center">
      <span class="text-h6">TRV händelse Id: {{ eventId }}</span>
      <q-space />
      <q-toggle v-model="showRawdata" label="Visa rådata" />
    </q-card-section>
    <q-separator />
    <q-card-section class="q-pa-none">
      <table v-if="mpkData" style="table-layout: fixed; width: 100%">
        <tbody>
          <template v-for="h in headers" :key="h.key">
            <tr v-if="mpkData && mpkData[h.key]">
              <td
                style="
                  width: 200px;
                  background: #f2f2f2;
                  font-weight: bold;
                  border-right: 1px solid rgba(0, 0, 0, 0.1);
                  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                "
                class="q-px-md q-py-xs"
              >
                {{ h.label }}
              </td>
              <td style="border-bottom: 1px solid rgba(0, 0, 0, 0.1)">
                <div style="overflow: auto; max-height: 200px" class="q-px-md">
                  {{ mpkData[h.key] }}
                </div>
              </td>
            </tr>
          </template>
          <tr v-if="showRawdata">
            <td
              style="
                width: 200px;
                vertical-align: top;
                background: #f2f2f2;
                font-weight: bold;
                border-right: 1px solid rgba(0, 0, 0, 0.1);
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
              "
              class="q-px-md q-py-xs"
            >
              Rådata
            </td>
            <td style="border-bottom: 1px solid rgba(0, 0, 0, 0.1)">
              <pre
                style="
                  overflow: auto;
                  max-height: 400px;
                  margin: 0;
                  font-size: 11px;
                  color: #444;
                "
                class="q-pa-md"
              >
                  
                  {{ JSON.stringify(mpkData, null, 2).trim() }}
                
                </pre
              >
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="flex items-center justify-center q-pa-xl text-grey-6">
        Ingen data hittades...
      </div>
    </q-card-section>

    <q-separator />
    <q-card-actions align="right">
      <q-btn flat label="Stäng" @click="modal.closeModal" type="button" />
    </q-card-actions>
  </q-card>
</template>

<script lang="ts">
import { UseModal } from '@/composable/useModal'
import { defineComponent, inject, ref } from 'vue'
import { DeviationDelayMpkModalData } from '@/types/deviation-delay-mpk-modal-data'

export default defineComponent({
  name: 'DeviationDelayMPKModal',

  setup() {
    const showRawdata = ref(false)
    const modal =
      inject<UseModal<DeviationDelayMpkModalData>>('delay-mpk-modal')!
    const { eventId, mpkData } = modal.state.data!

    const headers = [
      {
        label: 'Rubrik',
        key: 'Header',
      },
      {
        label: 'Händelsebeskrivning (intern)',
        key: 'InternalText',
      },
      {
        label: 'Händelsebeskrivning (extern)',
        key: 'ExternalDescription',
      },
    ]

    return { modal, eventId, mpkData, headers, showRawdata }
  },
})
</script>
